import React, { useState } from 'react'

const Context = React.createContext()

const ContextProvider = ({ children }) => {
  const [lang, setLang] = useState('cat')

  return (
    <Context.Provider value={{lang, setLang}}>
      {children}
    </Context.Provider>
  )
}

export { ContextProvider, Context }